import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MonsterGet, CustomerAccount, Schedule, OrderItem, JobSite, Invoice, InvoiceItem, ScheduleMap, JobRoute } from '../models/smonster';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})


export class OrderComponent implements OnInit, OnDestroy {
  powers: string[] = ['very good', 'strong'];
  httpOptions: { headers: HttpHeaders; };
  accounts: CustomerAccount[] = [];
  companyName: string = 'Downey\'s of Granville';
  step: string = 'landing';
  accountResults: CustomerAccount[];
  searchText: string = '';
  siteStep: string = '';
  jobStep: string = '';
  account: CustomerAccount = new CustomerAccount();
  accountSites: JobSite[];
  accountSite: JobSite = new JobSite();
  orderItems: OrderItem[];
  addOnList: OrderItem[];
  jobCategories: string[];
  selectionOrderItems: OrderItem[];
  addOnStepNumber = 0;
  invoiceItemId = 0;
  selectedInvoiceItem: InvoiceItem;
  invoice: Invoice = new Invoice();
  taxrate: number;
  minDate: NgbDateStruct;
  routeSchedules: ScheduleMap[] = [];
  dayStart = 9;
  dayEnd = 17;
  activeRoutes: JobRoute[];
  maxDate: { year: number; month: number; day: number; };
  sixMonthSchedule: Schedule[];
  isDisabled: (date: NgbDateStruct, current: { month: number; }) => boolean;
  selectedDateStartTimes: Date[];
  selectedAvailableTimeBlocks: any[];
  dateSelected: any;
  jobDate: Date;
  confirmationNumber: any;
  jobMin = 0;
  jobMinItem: OrderItem;
  //baseSMURl = 'https://api.servicemonster.net/v1/';
  baseSMURl: string;
  taxID: string;
  createdByCustomer = true;
  company: { name: string; id: string; baseSMURl: string };
  modalDesc: string;
  isAnyDateAvailable: boolean = false;
  constructor(private http: HttpClient, private calendar: NgbCalendar, private route: ActivatedRoute,private modalService: NgbModal) {
    window["order"] = this;
    try {
      this.company = environment.companies.find(x => x.name === this.route.snapshot.paramMap.get('company').toLowerCase());
      this.baseSMURl = this.company.baseSMURl;
    }
    catch(e) {
      //redirect to 404 page
    } 
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + this.company.id,
        'Content-Type': 'application/json'
      })
    };
    this.getTaxRate();
    this.getActiveRoutes();
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const sixMonthsFromNow = new Date(today);
    sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
    this.minDate = { year: tomorrow.getFullYear(), month: tomorrow.getMonth() + 1, day: tomorrow.getDate() };
    this.maxDate = { year: sixMonthsFromNow.getFullYear(), month: sixMonthsFromNow.getMonth() + 1, day: sixMonthsFromNow.getDate() };
    this.getSchedule(today, sixMonthsFromNow);
    this.isDisabled = (date: NgbDateStruct, current: { month: number }) => {
      let disabled = true;
      this.routeSchedules.forEach(rs => {
        const ts = rs.getAvailableTimeSlotsForDay(date.year, date.month, date.day, this.dayStart, this.dayEnd, this.getJobTime(), true);
        if (ts.length > 0) {
          disabled = false;
          this.isAnyDateAvailable = true;
        }
      })
      return disabled;
    }
  }

  ngOnInit() {
    
  }
  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    // ...
    console.log('unload')
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander(event) {
    // ...
    if (this.invoice.orderRecordID && !this.invoice.jobRecordID) this.updateAbandonedOrder();
  }
  ngOnDestroy() {
    if (this.invoice.orderRecordID && !this.invoice.jobRecordID) this.updateAbandonedOrder();
    // if they abandon the order and we know email user with as much info as we have about it..
    //if the invoice doesn't have a job id delete the order ?
    //do I need to delete the line items too?
    //maybe keep it around and pull it up if they come back?
  }
  createNewAccount() {
    const postObs = this.http.post<any>(this.baseSMURl + 'accounts', JSON.stringify(this.account), this.httpOptions).subscribe(x => {
      const getObs = this.http.get<CustomerAccount>(this.baseSMURl + 'accounts/' + x.recordID, this.httpOptions).subscribe(y => {
        this.account = { ...y };
        this.getAccountSites();
        postObs.unsubscribe();
        getObs.unsubscribe();
      });
    });
  }

  findAccount() {
    const pat = /[1-9]/g;
    const isAddress = this.searchText.match(pat);
    if (isAddress) {
      const accountSearchObs = this.findAccountByAddress(this.searchText).subscribe(x => {
        if (x.count > 0) {
          this.accountResults = x.items;
          this.step = 'selectAccount';
        }
        else {
          this.step = 'accountNotFound';
        }
        accountSearchObs.unsubscribe();
      });
    }
    else {
      const accountSearchObs = this.findAccountByName(this.searchText).subscribe(x => {
        this.accountResults = x.items;
        if (x.count > 0) {
          this.accountResults = x.items;
          this.step = 'selectAccount';
        }
        else {
          this.step = 'accountNotFound';
        }
        accountSearchObs.unsubscribe();
      });
    }

  }
  selectAccount(account: CustomerAccount) {
    this.account = { ...account };
    this.getAccountSites();
  }

  selectSite(site: JobSite) {
    this.accountSite = { ...site };
    this.createJob();
  }
  getAccountSites() {
    const obs = this.http.get<MonsterGet<JobSite>>(this.baseSMURl + 'accounts/' + this.account.accountID + '/sites', this.httpOptions).subscribe(x => {
      this.accountSites = x.items;
      this.step = 'selectSite';
      obs.unsubscribe();
    });
  }

  createNewSite() {
    this.accountSite.accountID = this.account.accountID;
    this.accountSite.name = this.accountSite.address1;
    if (this.accountSite.address2) this.accountSite.name += ' ' + this.accountSite.address2;
    const postObs = this.http.post<any>(this.baseSMURl + 'sites', JSON.stringify(this.accountSite), this.httpOptions).subscribe(x => {
      const getObs = this.http.get<JobSite>(this.baseSMURl + 'sites/' + x.recordID, this.httpOptions).subscribe(y => {
        this.accountSite = { ...y };
        this.createJob();
        postObs.unsubscribe();
        getObs.unsubscribe();
      });
    });
  }

  tryAgainClick() {
    this.searchText = '';
    this.step = 'findAccount';
  }

  findAccountByAddress(address: string) {
    return this.http.get<MonsterGet<CustomerAccount>>
      (this.baseSMURl + 'accounts?wField=address1&wValue=' + address + '&wField=address2&wValue=' + address, this.httpOptions);
  }
  findAccountByName(name: string) {
    return this.http.get<MonsterGet<CustomerAccount>>
      (this.baseSMURl + 'accounts?wField=accountName&wOperator=like&wValue=' + name, this.httpOptions);
  }

  getSchedule(startDate: Date, endDate: Date) {
    const sub = this.http.get<MonsterGet<Schedule>>(this.baseSMURl + 'scheduling?startDate='
      + startDate.toLocaleDateString() + '&endDate=' + endDate.toLocaleDateString(), this.httpOptions).subscribe(x => {
        this.sixMonthSchedule = x.items;
        sub.unsubscribe();
      })
  }

  getOrderItems() {
    return this.http.get<MonsterGet<OrderItem>>
      (this.baseSMURl + 'orderitems?limit=250', this.httpOptions);
  }

  updateAbandonedOrder() {
    const order = { accountID: this.account.accountID, dateCreated: new Date(), siteID: this.accountSite.siteID, taxID: this.taxID, note: 'abandoned',orderType: 'Voided', flagForReview: true };
    this.http.patch<any>(this.baseSMURl + 'orders/' + this.invoice.orderRecordID , order, this.httpOptions).subscribe(orderResults => {
    });
  }

  createUnscheduledOrder() {
    const order = { accountID: this.account.accountID, dateCreated: new Date(), siteID: this.accountSite.siteID, taxID: this.taxID, note: this.invoice.notes,orderType: 'Estimate', flagForReview: true };
    this.http.patch<any>(this.baseSMURl + 'orders/' + this.invoice.orderRecordID , order, this.httpOptions).subscribe(orderResults => {
      this.step = 'success';
    });
  }

  createOrderInline() {
    const order = { accountID: this.account.accountID, dateCreated: new Date(), siteID: this.accountSite.siteID, taxID: this.taxID };
    this.http.post<any>(this.baseSMURl + 'orders', order, this.httpOptions).subscribe(orderResults => {
      this.invoice.orderRecordID = orderResults.recordID;
    });
  }

  createLineItem(item: InvoiceItem) {
    let totalPrice = item.orderItem.price;
    let desc = '';
    item.addOns.forEach(addon => {
      if (desc.indexOf(addon.displayName) === -1) {
        desc += addon.displayName + ' ';
        totalPrice += addon.price;
      }
    });
    const liItem = { itemID: item.orderItem.itemID, orderID: this.invoice.orderRecordID, quantity: item.quantity, taxed: item.orderItem.taxed, price: totalPrice, description: desc }
    this.http.post<any>(this.baseSMURl + 'lineitems', liItem, this.httpOptions).subscribe(lineItemResults => {
      this.invoice.items.find(ii => ii.InvoiceItemID === item.InvoiceItemID).lineItemRecordID = lineItemResults.recordID;
    });
  }

  finishUnscheduled() {
    if (this.invoice.minNotMet) {
      console.log('min not met')
      const item = this.jobMinItem;
      //add the min item
      const liItem = { itemID: item.itemID, orderID: this.invoice.orderRecordID, quantity: 1, taxed: true, price: item.price, description: '' }
      this.http.post<any>(this.baseSMURl + 'lineitems', liItem, this.httpOptions).subscribe(lineItemResults => {
        this.createUnscheduledOrder();
      });
    }
    else {
      this.createUnscheduledOrder();
    }
  }

  finish() {
    if (this.invoice.minNotMet) {
      console.log('min not met')
      const item = this.jobMinItem;
      //add the min item
      const liItem = { itemID: item.itemID, orderID: this.invoice.orderRecordID, quantity: 1, taxed: true, price: item.price, description: '' }
      this.http.post<any>(this.baseSMURl + 'lineitems', liItem, this.httpOptions).subscribe(lineItemResults => {
        this.createJobInline();
      });
    }
    else {
      this.createJobInline();
    }
  }

  createJobInline() {
    let selectedRoute = '';
    const day = new Date(this.jobDate).getDate();
    const month = new Date(this.jobDate).getMonth() + 1;
    const year = new Date(this.jobDate).getFullYear();
    let startHour = new Date(this.jobDate).getHours();
    startHour += (new Date(this.jobDate).getMinutes() === 30) ? .5 : 0;
    const endTime = new Date(this.jobDate);
    endTime.setMinutes(endTime.getMinutes() + (this.getJobTime() * 60));
    this.routeSchedules.forEach(rs => {
      if (!rs.itemScheduledOnHour(year, month, day, startHour)) { //find the first route available (change to take into account a routes load for the day/week/month and select the one with the lowest load)
        selectedRoute = rs.routeID;
        return;
      }
    });
    if (this.createdByCustomer) {
      if (this.invoice.notes) this.invoice.notes = 'Online \n' + this.invoice.notes;
      else this.invoice.notes = 'Online \n';
    }
    const job = { accountID: this.account.accountID, orderID: this.invoice.orderRecordID, routeID: selectedRoute, note: this.invoice.notes, estDateTimeStart: this.formatDateToJSON(this.jobDate), estDateTimeEnd: this.formatDateToJSON(endTime) }
    console.log(job);
    this.http.post<any>(this.baseSMURl + 'jobs', job, this.httpOptions).subscribe(jobRes => {
      // email user
      // email customer
      this.invoice.jobRecordID = jobRes.recordID;
      this.step = 'success';
    })
  }


  createOrder() {
    const lineItemObs: Observable<any>[] = [];
    let order = { accountID: this.account.accountID, dateCreated: new Date(), siteID: this.accountSite.siteID }
    let orderTotal = 0;
    //create order
    this.http.post<any>(this.baseSMURl + 'orders', order, this.httpOptions).subscribe(orderResults => {
      this.invoice.items.forEach(item => {
        let totalPrice = item.orderItem.price;
        let desc = '';
        item.addOns.forEach(addon => {
          if (desc.indexOf(addon.displayName) === -1) {
            desc += addon.displayName + ' ';
            totalPrice += addon.price;
          }
        });
        orderTotal += totalPrice;
        const liItem = { itemID: item.orderItem.itemID, orderID: orderResults.recordID, quantity: item.quantity, taxed: item.orderItem.taxed, price: totalPrice, description: desc }
        const li = this.http.post(this.baseSMURl + 'lineitems', liItem, this.httpOptions);
        lineItemObs.push(li);
      });
      if (orderTotal < this.jobMin) {
        const jobMinPrice = this.jobMin - orderTotal;
        const liItem = { itemID: this.jobMinItem.itemID, orderID: orderResults.recordID, quantity: 1, taxed: true, price: jobMinPrice }
        const li = this.http.post(this.baseSMURl + 'lineitems', liItem, this.httpOptions);
        lineItemObs.push(li);
      }
      forkJoin(lineItemObs).subscribe(x => {
        let selectedRoute = '';
        console.log(x);
        console.log(this.jobDate)
        const day = new Date(this.jobDate).getDate();
        const month = new Date(this.jobDate).getMonth() + 1;
        const year = new Date(this.jobDate).getFullYear();
        let startHour = new Date(this.jobDate).getHours();
        startHour += (new Date(this.jobDate).getMinutes() === 30) ? .5 : 0;
        const endTime = new Date(this.jobDate);
        endTime.setMinutes(endTime.getMinutes() + (this.getJobTime() * 60));
        this.routeSchedules.forEach(rs => {
          if (!rs.itemScheduledOnHour(year, month, day, startHour)) { //find the first route available (change to take into account a routes load for the day/week/month and select the one with the lowest load)
            selectedRoute = rs.routeID;
            return;
          }
        });
        const job = { accountID: this.account.accountID, orderID: orderResults.recordID, routeID: selectedRoute, note: this.invoice.notes, estDateTimeStart: this.formatDateToJSON(this.jobDate), estDateTimeEnd: this.formatDateToJSON(endTime) }
        console.log(job);
        this.http.post<any>(this.baseSMURl + 'jobs', job, this.httpOptions).subscribe(jobRes => {
          // email user
          // email customer
          this.step = 'success';
        })
      })
    });
  }

  formatDateToJSON(date: Date) {
    //2019-07-28T06:00:00
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    if (hours.length == 1) hours = '0' + hours;
    if (minutes.length == 1) minutes = '0' + minutes;

    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + hours + ':' + minutes + ':00';
  }
  getActiveRoutes() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 6);
    const sub = this.http.get<MonsterGet<JobRoute>>
      (this.baseSMURl + 'routes', this.httpOptions).subscribe(x => {
        this.activeRoutes = x.items.filter(y => y.active);

        sub.unsubscribe();
      });
  }

  createJob() {
    this.getItems();
    this.createOrderInline();
    this.step = 'createJob';
  }

  getTaxRate() {

    const taxObs = this.http.get<MonsterGet<any>>
      (this.baseSMURl + 'taxrates', this.httpOptions).subscribe(x => {
        this.taxID = x.items[0].taxID;
        this.taxrate = x.items[0].rate / 100;
        taxObs.unsubscribe();
      });
  }

  getSite() { }
  startOver() {
    location.reload();
  }
  getItems() {
    this.orderItems = [];
    this.addOnList = [];
    const orderItemObs = this.http.get<MonsterGet<OrderItem>>
      (this.baseSMURl + 'orderitems?wField=tags&wValue=online&wOperator=like&limit=250', this.httpOptions).subscribe(x => {
        x.items.forEach(y => {
          const newItem = this.extractTags(y);
          if (newItem.isAddOn) {
            this.addOnList.push(newItem);
          }
          else if (newItem.name === 'Minimum') {
            this.jobMinItem = newItem;
            this.jobMin = newItem.price;
          }
          else this.orderItems.push(newItem);
        });
        this.orderItems.forEach(y => {
          if (y.addOnItemIds) {
            y.addOnItems = [];
            y.addOnItemIds.forEach(aoi => {
              const foundItem = this.addOnList.find(aoli => aoli.itemID === aoi);
              if (foundItem) y.addOnItems.push(foundItem);
            });
          }

        });
        this.jobCategories = Array.from(new Set(this.orderItems.map(y => y.category)));
        orderItemObs.unsubscribe();
      });
  }
  extractTags(item: OrderItem) {
    try {
      item.category = item.tags.match(/#"([^']*?)"+/gi)[0].substring(1).replace(/"/g, '');
    }
    catch (e) { }
    try {
      item.displayName = item.tags.match(/@"([^']*?)"+/gi)[0].substring(1).replace(/"/g, '');
    }
    catch (e) { }
    item.exemptFromMin = item.tags.indexOf('minExempt') !== -1;
    if (item.tags.indexOf('addon') !== -1) {
      item.isAddOn = true;
      if (item.tags.indexOf('%') !== -1) {
        item.isPercentPrice = true;
        try {
          item.pricePercent = +item.tags.match(/\%[\w\,\-]+/gi)[0].substring(1);
        }
        catch (e) { console.log(e) }
      }
    }
    else {
      item.isAddOn = false;
      try {
        item.addOnItemIds = item.tags.match(/\$[\w\,\-]+/gi)[0].substring(1).split(',');

      }
      catch (e) { }
    }

    return item;
  }
  selectedCategory(category: string) {
    //get items for category
    this.selectionOrderItems = this.orderItems.filter(x => x.category === category);
    this.jobStep = 'itemSelection';
  }

  selectItem(item: OrderItem) {
    this.selectedInvoiceItem = new InvoiceItem();
    this.selectedInvoiceItem.InvoiceItemID = this.invoiceItemId;
    this.invoiceItemId++;
    this.selectedInvoiceItem.orderItem = item;
    if (this.selectedInvoiceItem.orderItem.addOnItems && this.selectedInvoiceItem.orderItem.addOnItems.length > 0) {
      this.addOnStepNumber = 0;
      this.jobStep = 'addOnSelection';
    }
    else {
      this.invoice.items.push(this.selectedInvoiceItem);
      this.jobStep = 'quantity';
    }
  }

  addOnSelectNo(i: number) {
    if (this.selectedInvoiceItem.orderItem.addOnItems.length > i + 1) {
      this.addOnStepNumber++;
    }
    else {
      this.invoice.items.push(this.selectedInvoiceItem);
      if (!this.selectedInvoiceItem.quantity) this.jobStep = 'quantity';//probably unnecessary
      else this.jobStep = 'cart';
    }
  }

  addOnSelectYes(addOnItem: OrderItem, i: number) {
    if (addOnItem.isPercentPrice) {
      const addOnPrice = (addOnItem.pricePercent / 100) * this.selectedInvoiceItem.orderItem.price;
      addOnItem.price = addOnPrice;
    }
    this.selectedInvoiceItem.addOns.push(addOnItem);
    if (this.selectedInvoiceItem.orderItem.addOnItems.length > i + 1) {
      this.addOnStepNumber++;
    }
    else {
      this.invoice.items.push(this.selectedInvoiceItem);
      
      this.jobStep = 'quantity';
    }
  }

  addInvoiceItem() {
    this.createLineItem(this.selectedInvoiceItem);
    this.getTotals();
  }

  getTotals(isCheckout = false) {
    let sub = 0;
    this.invoice.items.forEach(x => {
      sub += x.orderItem.price * x.quantity;
      if (x.addOns.length > 0) {
        x.addOns.forEach(y => {
          sub += y.price * x.quantity;
        });
      }
    });
    let jobTime = 0;
    this.invoice.items.forEach(x => {
      jobTime += x.orderItem.avgTime * x.quantity;
      x.addOns.forEach(y => {
        jobTime += y.avgTime * x.quantity;
      });
    });
    this.invoice.jobTime = jobTime;
    this.invoice.sub = sub;
    console.log(sub < this.jobMin)
    console.log(!this.isOnlyMinExemptItems())
    console.log(sub < this.jobMin && !this.isOnlyMinExemptItems())
    if (sub < this.jobMin && !this.isOnlyMinExemptItems()) {
      this.jobMinItem.price = this.jobMin - sub;
      if (isCheckout) this.invoice.sub = this.jobMin;
      this.invoice.minNotMet = true;
    }
    else {
      this.invoice.minNotMet = false;
    }
    this.invoice.tax = sub * this.taxrate;
    this.invoice.total = this.invoice.sub + this.invoice.tax;
    
    this.jobStep = 'cart';
  }

  isOnlyMinExemptItems() {
    console.log("exempt")
    return this.invoice.items.some(x => x.orderItem.exemptFromMin);
  }

  formatMoney(amount: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(amount);
  }
  removeOrderItem(item: InvoiceItem) {
    this.http.delete<any>(this.baseSMURl + 'lineitems/' + item.lineItemRecordID, this.httpOptions).subscribe(res => {
      this.invoice.items = this.invoice.items.filter(x => x.InvoiceItemID !== item.InvoiceItemID);
      this.getTotals();
    });
  }
  // isDisabled(date: NgbDate, scheduleMaps: ScheduleMap[]) {

  //   const inDate = new Date(date.year, date.month, date.day);
  //   const day = inDate.getDate();
  //   const month = inDate.getMonth() + 1;
  //   const year = inDate.getFullYear();
  //   this.routeSchedules.forEach(rs => {
  //     if (rs.getAvailableTimeSlotsForDay(year, month, day, this.dayStart, this.dayEnd, this.getJobTime(), true).length > 0) {
  //       return true;
  //     }
  //   })
  //   return false;
  // }

  getJobTime() {
    const jobTime = (Math.round((this.invoice.jobTime / 60) * 2) / 2);
    if (jobTime === 0) return .5;
    return jobTime;
  }

  prepareSchedule() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 6);
    this.getRouteSchedule();
    //this.getAvailableSchedule(startDate, endDate);
    this.step = 'schedule';

  }

  getRouteSchedule() {
    const routeSchedule = new ScheduleMap();
    this.activeRoutes.forEach(r => {
      //const schSub = this.getSchedule(startDate, endDate, r.routeID).subscribe(schedule => {
      this.sixMonthSchedule.filter(sch => sch.routeID = r.routeID).forEach(scheduleItem => {
        const day = new Date(scheduleItem.startDateTime).getDate();
        const month = new Date(scheduleItem.startDateTime).getMonth() + 1;
        const year = new Date(scheduleItem.startDateTime).getFullYear();
        let startHour = new Date(scheduleItem.startDateTime).getHours();
        startHour += (new Date(scheduleItem.startDateTime).getMinutes() === 30) ? .5 : 0;
        let endHour = new Date(scheduleItem.endDateTime).getHours();
        endHour += (new Date(scheduleItem.endDateTime).getMinutes() === 30) ? .5 : 0;
        for (let i = startHour; i < endHour; i += .5) {
          routeSchedule.addScheduleItem(year, month, day, i);
        }
      });
      routeSchedule.routeID = r.routeID;
      this.routeSchedules.push(routeSchedule);//make hashmap
    });
  }

  // getAvailableSchedule(startDate: Date, endDate: Date) {
  //   const currentDate = new Date(startDate);
  //   this.routeSchedules.forEach(rs => {
  //     while(currentDate.getTime() < endDate.getTime()) {
  //       const day = currentDate.getDate();
  //       const month = currentDate.getMonth() + 1;
  //       const year = currentDate.getFullYear();
  //       rs.getAvailableTimeSlotsForDay(year, month, day, this.dayStart, this.dayEnd, this.getJobTime(), true).forEach(x => {
  //         this.availableStartTimes.add(x);
  //       })
  //       currentDate.setDate(currentDate.getDate() + 1);
  //     }
  //   });
  // }

  getAvailableScheduleForDay(year: number, month: number, day: number) {
    const startTimes: Date[] = [];
    this.routeSchedules.forEach(rs => {
      rs.getAvailableTimeSlotsForDay(year, month, day, this.dayStart, this.dayEnd, this.getJobTime(), true).forEach(x => {
        if (!startTimes.some(y => y.getTime() === x.getTime())) {
          startTimes.push(x);
        }
      });
    });
    return startTimes;
  }

  selectedDate(date: NgbDate) {
    const startTimes = this.getAvailableScheduleForDay(date.year, date.month , date.day);
    this.selectedDateStartTimes = startTimes;
    this.selectedAvailableTimeBlocks = this.getAvailableStartTimeBlocks(startTimes, date.year, date.month - 1, date.day);
  }

  monthChange() {
    this.dateSelected = null;
    this.selectedDateStartTimes = [];
    this.selectedAvailableTimeBlocks = [];
  }
  getAvailableStartTimeBlocks(startTimes: Date[], year: number, month: number, day: number) {
    let currentBlockStart: Date = null;
    let previousTime: Date = null;
    const blocks = [];
    for (let i = this.dayStart; i <= this.dayEnd; i += .5) {
      const hour = (i % 1 === 0) ? i : i - .5;
      const minutes = (i % 1 === 0) ? 0 : 30;
      const currentTime = new Date(year, month, day, hour, minutes);
      console.log(currentTime);
      if (!currentBlockStart) {
        console.log(startTimes.some(st => st.getTime() === currentTime.getTime()));
        if (startTimes.some(st => st.getTime() === currentTime.getTime())) {
          currentBlockStart = new Date(currentTime);
        }
      }
      else {
        if (!startTimes.some(st => st.getTime() === currentTime.getTime())) {
          blocks.push({ blockStart: new Date(currentBlockStart), blockEnd: new Date(previousTime) });
          currentBlockStart = null;
        }
      }
      previousTime = new Date(currentTime);
    }

    return blocks;
  }

  formatTime(inTime: Date) {
    return inTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }

  selectTime(time) {
    this.jobDate = new Date(time);
    this.getTotals(true);
    this.step = 'checkout';
  }

  selectSubmitWithoutScheduling() {
    this.jobDate = null;
    this.getTotals(true);
    this.step = 'checkout';
  }

  //ScheduleMap needs the route id set..
  //start and end times are always on the half hour
  //So schedule map needs an minutes map
  //12:00am = 0 6:30pm = 18.5 {date.getHours() * 2 + date.getminutes() === 0 ? 0 : .5}
  //set this in getRouteSchedule
  //create a function that takes a time as number ie 18.5 
  //this spins through the a routes schedules checking the maps to see if this is a valid start time (time: number, route: string)
  //give a half hour buffer on each side? (maybe configurable)
  //jobTime needs to be rounded to nearest half hour
  //create a function to iterate from 0 to 24 incrimenting by .5 for each of the routes which calls the above function
  //this should terminate as soon as we find a route.
  //and returns a list of available start times
  //if that list is empty or null the day should be disabled
  //that list should be displayed on day select


  // getAvailableTimeSlots(year:number, month: number, day: number) {
  //   const startTime = 'T09:00:00';//needs to be configureable global
  //   const endTime = 'T17:00:00';//needs to be configureable global
  //   const aveTravelTime = 20; //config
  //   let jobTime = 0;
  //   this.invoice.items.forEach(x => {
  //     jobTime += x.orderItem.aveTime * x.quantity;
  //     x.addOns.forEach(y => {
  //       jobTime += y.aveTime * x.quantity;
  //     });
  //   });
  //   const dayStartTime = new Date(year + '-' + month + '-' + day + startTime);
  //   const dayEndTime = new Date(year + '-' + month + '-' + day + endTime);
  //   this.routeSchedules.forEach(rs => {
  //     const scheduledJobs = rs.getScheduleItem(year, month, day);
  //     scheduledJobs.sort((a: Schedule, b: Schedule) => {
  //       return a.startDateTime.getTime() - b.startDateTime.getTime();
  //     });
  //     let currentStartTime = new Date(dayStartTime);
  //     for (let i = 0; i <= scheduledJobs.length; i++) {
  //       const nextJobStartTime = (i < scheduledJobs.length) ? scheduledJobs[i].startDateTime : new Date(dayEndTime);
  //       const nextJobEndTime = (i < scheduledJobs.length) ? scheduledJobs[i].endDateTime : new Date(dayEndTime);
  //       const timeBetweenJobs = nextJobStartTime.getTime() - currentStartTime.getTime();

  //       const minutesBetweenJobs = timeBetweenJobs >= 60000 ? timeBetweenJobs / 60000 : 0;
  //       let timePlusTravel = jobTime + (2 * aveTravelTime);
  //       if (i === scheduledJobs.length || i === 0) {
  //         timePlusTravel = jobTime + aveTravelTime;
  //       }
  //       if (minutesBetweenJobs >= timePlusTravel) {
  //         const lastStartTime = (i !== scheduledJobs.length) ? new Date(currentStartTime.getTime() - (aveTravelTime * 60000)) : 
  //         new Date(currentStartTime.getTime()); // gives travel time before next job or not if this is the last job of the day
  //         const firstStartTime = (i !== 0) ? new Date(currentStartTime.getTime() + (aveTravelTime * 60000)) : 
  //           new Date(currentStartTime.getTime()); //should give travel time before or not if this is the first job of the day
  //         currentStartTime = new Date(nextJobEndTime);
  //         const openTimeSlot = new Schedule();
  //         openTimeSlot.startDateTime = firstStartTime;
  //         openTimeSlot.endDateTime = lastStartTime;
  //         openTimeSlot.routeID = rs.routeID; //need to set
  //         this.availableTimeSlots.push(openTimeSlot);
  //       }

  //     }


  //   });
  // }
  
  showDesc(content, modalDesc) {
    this.modalDesc = modalDesc;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }
  hideDesc() {
    this.modalService.dismissAll('Cancel');
  }

}
