// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCOPu3rzRffGycbP9UrVix77GDtQv4UeVw",
    authDomain: "ezservicebooking.firebaseapp.com",
    databaseURL: "https://ezservicebooking.firebaseio.com",
    projectId: "ezservicebooking",
    storageBucket: "",
    messagingSenderId: "782317047075",
    appId: "1:782317047075:web:5888314b92fc0bef"
  },
  whitelist : [
    {
      email: 'josephpdowney17@gmail.com',
      company: 'test'
    },
    {
      email: 'jdowney@johndowneyco.com',
      company: 'downeys'
    },
    // {
    //   email: 'josephpdowney17@gmail.com',
    //   company: 'downeys'
    // }
  ],
  companies : [{
    name: 'test',
    id: 'NnBCUFhMdTE6N1NXNkdCN0ZtWmxaclB6N0RjTw==',
    baseSMURl : 'https://west-test.servicemonster.net/smapi/v1/'
  },
  {
    name: 'downeys',
    id: 'QjF4WlZvYkIxOmpCbU9jajNlVjUyd2lXQXZUZg==',
    baseSMURl : 'https://api.servicemonster.net/v1/'
  }
],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
