

export class MonsterGet<T> {
    count: number;
    items: T[];
}

export class CustomerAccount {
    row_number: number;
    accountID: string;
    accountName: string;
    accountReferralID: string;
    accountType: string;
    accountSubType: string;
    acquisitionDate: Date;
    active: boolean;
    address1: string;
    address2: string;
    alert: boolean;
    city: string;
    comment: string;
    commercial: boolean;
    companyID: string;
    companyKey: string;
    companyName: string;
    country: string;
    county: string;
    courierRoute: string;
    doNotCall: boolean;
    doNotEmail: boolean;
    doNotMail: boolean;
    email: boolean;
    externalRefID: string;
    fax: string;
    firstName: string;
    lastName: string;
    lastStatementDate: Date;
    lastJobDate: Date;
    leadSourceID: string;
    nextJobDate: Date;
    note: string;
    paymentTerm: string;
    phone1: string;
    phone1Label: string;
    phone2: string;
    phone2Label: string;
    phone3: string;
    phone3Label: string;
    reference: string;
    salutation: string;
    securityGroup: string;
    state: string;
    taxExempt: boolean;
    taxID: string;
    zip: string;
    tempStamp: Date;
}

export class Schedule {
    startDateTime: Date;
    endDateTime: Date;
    routeID: string;
}


export class JobSite {
    name: string;
    siteID: string;
    accountID: string;
    active: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}

export class OrderItem {
    itemID: string;
    active: boolean;
    avgTime: number;
    commissionable: boolean;
    companyID: string;
    description: string;
    itemType: string;
    itemGroup: string;
    name: string;
    displayName: string;
    price: number;
    pricePercent: number;
    quantity: number;
    taxed: boolean;
    unitOfMeasure: string;
    userName: string;
    priceLocked: boolean;
    addToFavorites: boolean;
    upsaleItem: boolean;
    subGroup: string;
    taxGroup: string;
    tags: string;
    priceList: string;
    quickCode: string;
    isAddOn: boolean;
    isPercentPrice: boolean;
    isUnitPrice: boolean;
    category: string;
    addOnItemIds: string[];
    addOnItems: OrderItem[];
    exemptFromMin: boolean;
}

export class InvoiceItem {
    lineItemRecordID: string;
    InvoiceItemID: number;
    orderItem: OrderItem;
    addOns: OrderItem[] = [];
    quantity: number;
}

export class JobRoute {
    routeID: string;
    active: true;
}

export class Invoice {
    orderRecordID: string;
    jobRecordID: string;
    items: InvoiceItem[] = [];
    jobTime: number; //minutes
    notes: string;
    sub: number;
    tax: number;
    total: number;
    minNotMet: boolean;
}
export class ScheduleMap {
    private bigMap: Map<number, Map<number, Map<number, Map<number, boolean>>>> = new Map<number, Map<number, Map<number, Map<number, boolean>>>>();
    routeID: string;
    addScheduleItem(year: number, month: number, day: number, hour: number) {
        if (this.bigMap.has(year)) {
            const yearMap = this.bigMap.get(year);
            if (yearMap.has(month)) {
                const monthMap = yearMap.get(month);
                if (monthMap.has(day)) {
                    const dayMap = monthMap.get(day);
                    dayMap.set(hour, true);
                }
                else {
                    const dayMap = new Map<number, boolean>();
                    dayMap.set(hour, true);
                    monthMap.set(day, dayMap);
                }
            }
            else {
                const dayMap = new Map<number, boolean>();
                const monthMap = new Map<number, Map<number, boolean>>();
                dayMap.set(hour, true);
                monthMap.set(day, dayMap);
                yearMap.set(month, monthMap);
            }
        }
        else {
            const dayMap = new Map<number, boolean>();
            const monthMap = new Map<number, Map<number, boolean>>();
            const yearMap = new Map<number, Map<number, Map<number, boolean>>>();
            dayMap.set(hour, true);
            monthMap.set(day, dayMap);
            yearMap.set(month, monthMap);
            this.bigMap.set(year, yearMap);
        }
    }


    itemScheduledOnHour(year: number, month: number, day: number, hour: number) {
        try {
            if (this.bigMap.get(year).get(month).get(day).get(hour)) return true;
        }
        catch (e) {
            return false;
        }
    }


    //a timeslot is every half hour 1-24 with half hours as .5
    getAvailableTimeSlotsForDay(year: number, month: number, day: number, dayStart: number, dayEnd: number, jobTime: number, allowTravelTime: boolean) {
        const availableTimeSlots: Date[] = [];
        for (let i = dayStart; i <= dayEnd; i += .5) {
            let travelTime = 0;
            //if there is a previous block and a job scheduled in the previous block add a half hour
            //if theres is a next block and a job scheduled in the next block add a half hour
            if (allowTravelTime) {
                // if (i - .5 > dayStart && this.itemScheduledOnHour(year, month, day, i - .5)) {
                //     travelTime += .5;
                // }
                // if (i + .5 < dayEnd && this.itemScheduledOnHour(year, month, day, i + .5)) {
                //     travelTime += .5;
                // }
            }

            const totalJobTime = travelTime + jobTime;
            if (totalJobTime + i > dayEnd) { //no more possible start times
                break;
            }
            let canSchedule = true;
            for (let j = i; j <= totalJobTime + i; j += .5) {
                if (this.itemScheduledOnHour(year, month, day, j) || 
                (j - .5 > dayStart && this.itemScheduledOnHour(year, month, day, j - .5)) ||
                (j + .5 < dayEnd && this.itemScheduledOnHour(year, month, day, j + .5))) {
                    canSchedule = false;
                    break;
                }
            }
            if (canSchedule) {
                const hour = (i % 1 === 0) ? i : i - .5;
                const minutes = (i % 1 === 0) ? 0 : 30;
                const scheduleStartTime = new Date(year, month - 1, day, hour, minutes);
                availableTimeSlots.push(scheduleStartTime);
            }
        }
        return availableTimeSlots;
    }
}