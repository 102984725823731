import { Component, OnInit } from '@angular/core';
import { OrderItem, MonsterGet } from '../models/smonster';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  addOnList: OrderItem[];
  selectedAddOns: OrderItem[] = [];
  dropdownSettings: {
    singleSelection: boolean; idField: string; textField: string;
    selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean;
  };

  orderItem: OrderItem = new OrderItem();
  orderItems: OrderItem[];
  httpOptions: { headers: HttpHeaders; };
  itemTypes: string[] = ['Service', 'Product'];
  isCreate = true;
  closeResult: string;
  jobMin = 0;
  baseSMURl: string;
  company: { name: string; id: string; baseSMURl: string };
  //baseSMURl = 'https://api.servicemonster.net/v1/';
  constructor(private http: HttpClient, private modalService: NgbModal,private route: ActivatedRoute) {
    window['admin'] = this;
    try {
      this.company = environment.companies.find(x => x.name === this.route.snapshot.paramMap.get('company').toLowerCase());
      this.baseSMURl = this.company.baseSMURl;
    }
    catch(e) {
      //redirect to 404 page
    } 
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + this.company.id,
        'Content-Type': 'application/json'
      })
    };
    this.getItems();

  }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'itemID',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  sortBy(column) {
    const firstType = this.orderItems[0][column];
    let secondType = this.orderItems[1][column];
    if (firstType === secondType) {
      let i = 2;
      while (secondType === firstType && i < this.orderItems.length ) {
        secondType = this.orderItems[i][column];
        i++;
      }
    }
    const isAsc = firstType > secondType;
    this.orderItems.sort((a, b) => ((a[column] > b[column]) === isAsc ? 1 : -1));
  }

  getDisplayPrice(item: OrderItem) {
    if (item.isAddOn) {
      if (item.isPercentPrice) {
        return item.pricePercent + '%';
      }
      else {
        return '$' + item.price;
      }
    }
    else {
      if (item.isUnitPrice) {
        return '$' + item.price + '/' + item.unitOfMeasure.split('/')[0];
      }
      else {
        return '$' + item.price;
      }
    }
  } 

  newItem(content) {
    this.isCreate = true;
    this.orderItem = new OrderItem();
    this.selectedAddOns = [];
    this.orderItem.unitOfMeasure = 'Square Foot/Square Feet';
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }
  editItem(content, item: OrderItem) {
    this.isCreate = false;
    this.orderItem = {...item};
    if (!this.orderItem.isUnitPrice) this.orderItem.unitOfMeasure = 'Square Foot/Square Feet';
    this.selectedAddOns = [];
    if (item.addOnItemIds && item.addOnItemIds.length > 0) {
      item.addOnItemIds.forEach(aoi => {
        const foundItem = this.addOnList.find(x => x.itemID === aoi);
        if (foundItem) this.selectedAddOns.push(foundItem);
      });
    }
    //this.selectedAddOns = (item.addOnItemIds && item.addOnItemIds.length > 0) ? this.addOnList.filter(x => item.addOnItemIds.some(y => y === x.itemID)) : [];
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  // migrateToProd(company: string) {
  //   const prodCompany = environment.companies.find(x => x.name === company.toLowerCase());
  //   const prodURL = prodCompany.baseSMURl;
  //   const prodOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Basic ' + prodCompany.id,
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   this.orderItems.forEach(o => {
  //     o.itemID = null;
  //     o.userName = null;
  //     o.companyID = null;
  //     const postObs = this.http.post<any>(prodURL + 'orderitems', JSON.stringify(o), prodOptions).subscribe(x => {
  //       console.log('added item: ' + o.name);
  //       postObs.unsubscribe();
  //     });
  //   });
  // }

  saveItem() {
    this.addTags();
    // if (this.orderItem.isUnitPrice) {
    //   this.orderItem.unitOfMeasure = 'feet';
    // }
    // else {
    //   this.orderItem.unitOfMeasure = '';
    // }
    if (!this.orderItem.isUnitPrice) {
      this.orderItem.unitOfMeasure = '';
    }
    if (this.isCreate) {
      const postObs = this.http.post<any>(this.baseSMURl + 'orderitems', JSON.stringify(this.orderItem), this.httpOptions).subscribe(x => {
        const getObs = this.http.get<OrderItem>(this.baseSMURl + 'orderitems/' + x.recordID, this.httpOptions).subscribe(y => {
          this.orderItem = { ...y };
          this.getItems();
          this.modalService.dismissAll('Saved');
          postObs.unsubscribe();
          getObs.unsubscribe();
        });
      });
    }
    else {
      const itemid = this.orderItem.itemID;
      this.orderItem.itemID = null;
      const postObs = this.http.patch<any>(this.baseSMURl + 'orderitems/' + itemid, JSON.stringify(this.orderItem), this.httpOptions).subscribe(x => {
        const getObs = this.http.get<OrderItem>(this.baseSMURl + 'orderitems/' + itemid, this.httpOptions).subscribe(y => {
          this.orderItem = { ...y };
          this.getItems();
          this.modalService.dismissAll('Saved');
          postObs.unsubscribe();
          getObs.unsubscribe();
        });
      });
    }
  }
  createMinimum() {
    const minItem = new OrderItem();
    minItem.name = 'Minimum';
    minItem.price = this.jobMin;
    minItem.displayName = 'Minimum';
    minItem.description = 'Minimum Order amount'
    minItem.itemType = 'Service';
    minItem.avgTime = 0;
    this.addTags(minItem);
    const postObs = this.http.post<any>(this.baseSMURl + 'orderitems', JSON.stringify(minItem), this.httpOptions).subscribe(x => {
      postObs.unsubscribe();
    })

  }
  deleteItem(item: OrderItem) {
    const deleteObs = this.http.delete<OrderItem>(this.baseSMURl + 'orderitems/' + item.itemID, this.httpOptions).subscribe(y => {
      this.getItems();
      deleteObs.unsubscribe();
    });
  }
  cancelItem() {
    this.modalService.dismissAll('Cancel');
  }
  getItems() {
    this.orderItems = [];
    this.addOnList = [];
    const orderItemObs = this.http.get<MonsterGet<OrderItem>>
      (this.baseSMURl + 'orderitems?wField=tags&wValue=online&wOperator=like&limit=250', this.httpOptions).subscribe(x => {
        x.items.forEach(y => {
          const newItem = this.extractTags(y);
          if (newItem.unitOfMeasure) {
            newItem.isUnitPrice = true;
          }
          else {
            newItem.isUnitPrice = false;
          }
          if (newItem.isAddOn) {
            this.addOnList.push(newItem);
          }
          this.orderItems.push(newItem);
        });
        if (!this.orderItems.some(o => o.name === 'Minimum')) {
          this.createMinimum();
        }
        orderItemObs.unsubscribe();
      });
      
  }
  extractTags(item: OrderItem) {
    try {
      item.category = item.tags.match(/#"([^']*?)"+/gi)[0].substring(1).replace(/"/g, '');
     
    }
    catch (e) { }
    try {
      item.displayName = item.tags.match(/@"([^']*?)"+/gi)[0].substring(1).replace(/"/g, '');
    }
    catch (e) { }
    item.exemptFromMin = item.tags.indexOf('minExempt') !== -1;
    if (item.tags.indexOf('addon') !== -1) {
      item.isAddOn = true;
      if (item.tags.indexOf('%') !== -1) {
        item.isPercentPrice = true;
        try {
          item.pricePercent = +item.tags.match(/\%[\w\,\-]+/gi)[0].substring(1);
        }
        catch (e) { console.log(e) }
      }

    }
    else {
      item.isAddOn = false;
      try {
        item.addOnItemIds = item.tags.match(/\$[\w\,\-]+/gi)[0].substring(1).split(',');

      }
      catch (e) { }
    }

    return item;
  }

  addTags(item = this.orderItem) {
    item.tags = 'online ';
    item.tags += (item.category) ? '#"' + item.category + '" ' : '#"" ';
    item.tags += '@"' + item.displayName + '" ';
    if (item.exemptFromMin) {
      item.tags += ' minExempt ';
    }
    if (item.isAddOn) {
      item.tags += 'addon ';
      if (item.isPercentPrice) {
        item.tags += '%' + item.pricePercent;
      }
    }
    else {
      item.tags += '$';
      console.log(this.selectedAddOns)
      this.selectedAddOns.forEach(x => {
        item.tags += x.itemID + ',';
      });
      item.tags.substring(0, item.tags.length - 1);
    }
  }
  

}


