import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loginStep = '';
  unauthorized = false;
  verifyFailed = false;
  loginTitle = 'Login'
  constructor(private fire: FirebaseService, private modalService: NgbModal,private router: Router) { 
    
  }

  ngOnInit() {
  }

  loginClick(content) {
    this.loginStep = 'email';
    this.unauthorized = false;
   
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }
  closeLogin() {
    this.modalService.dismissAll();
  }
  login(email: string, password: string) {
    if ( environment.whitelist.find(x => x.email === email.toLowerCase())) {
      this.fire.getEmailMethods(email).then(res => {
        console.log(res)
        if (res.length === 0) {
          this.fire.createUser(email, password).then(createRes => {
            const adminPage = this.fire.getCurrentUser().appUser.company + '/admin';
            this.router.navigate([adminPage]);
            this.modalService.dismissAll();
          }).catch(e => console.log(e));
        } else {
          this.fire.loginUser(email, password).then(loginRes => {
          console.log(loginRes);
          const adminPage = this.fire.getCurrentUser().appUser.company + '/admin';
          this.router.navigate([adminPage]);
          this.modalService.dismissAll();
          }).catch(e => this.unauthorized = true);
        }
      }).catch(e => console.log(e))
    }
    else {
      this.unauthorized = true;
    }
  }
  resetPassword(email) {
    this.fire.resetPassword(email);
    this.loginStep = 'resetPassword';
  }
  // confirmReset(code: string, newPassword: string) {
  //   this.fire.confirmReset(code, newPassword).then(x => {
  //     this.loginStep = 'login';
  //     this.unauthorized = false;
  //   }).catch(e => {
  //     this.verifyFailed = true;
  //   });
  // }
}
