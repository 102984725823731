import { Injectable } from '@angular/core';
import * as firebase from "firebase/app";
import "firebase/auth";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
  }

  getAuth() {
    return firebase.auth();
  }

  getEmailMethods(email: string) {
    return firebase.auth().fetchSignInMethodsForEmail(email)
  }
  createUser(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email,password);
  }
  loginUser(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email,password);
  }
  getCurrentUser() {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) return null;
    return {firebaseUser : currentUser, appUser : environment.whitelist.find(x => x.email === currentUser.email)}
  }
  getAppUser(currentUser: firebase.User) {
    return {firebaseUser : currentUser, appUser : environment.whitelist.find(x => x.email === currentUser.email)}
  }
  resetPassword(email: string) {
    firebase.auth().sendPasswordResetEmail(email).catch(e => console.log(e));
  }

  confirmReset(code: string, newPassword: string) {
    return firebase.auth().confirmPasswordReset(code, newPassword)
  }
}
