import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OrderComponent } from './order/order.component';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { FirebaseService } from './firebase.service';
import { Observable } from 'rxjs';

@Injectable()
class IsValidAdmin implements CanActivate {
  constructor(private fire: FirebaseService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    // const currentUser = this.fire.getCurrentUser();
    // const company = route.paramMap.get('company');
    // console.log(currentUser)
    // if (currentUser && currentUser.appUser.company === company) return true;
    // else return false;
    return new Promise((resolve, reject) => {
      this.fire.getAuth().onAuthStateChanged((user: firebase.User) => {
        const company = route.paramMap.get('company');
        if (user) {
          const appUser = this.fire.getAppUser(user).appUser;
          if (appUser.company === company) resolve(true);
          else resolve(false);
        } else {
          resolve(false);
        }
      });
    });
  }
}
const routes: Routes = [{ path: ':company/order', component: OrderComponent }, { path: ':company/admin', component: AdminComponent, canActivate: [IsValidAdmin] }, { path: '', component: HomeComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [IsValidAdmin]
})
export class AppRoutingModule { }
